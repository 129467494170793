import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EditorCv } from '../classes/editorCv';
import { Document, Packer, Paragraph, HeadingLevel, TextRun, Media, Table, TableCell, TableRow, WidthType, BorderStyle, AlignmentType, ShadingType, Footer, IHeaderOptions } from 'docx';
import { saveAs } from "file-saver";
import { Observable } from 'rxjs/internal/Observable';
import * as jspdf from 'jspdf';

// Handles creation of files and local download of files
@Injectable()
export class FileService {

    private pdfOptions = {
        imgType: 'PNG',
        x: -2,
        y: 0,
        width: 212,
        height: 298
    }

    constructor(private http: HttpClient) { }

    createPdf(contentDataURL: string): jspdf {
        var pdf = new jspdf({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            compress: true
        });
        pdf.addImage(contentDataURL, this.pdfOptions.imgType, this.pdfOptions.x, this.pdfOptions.y, this.pdfOptions.width, this.pdfOptions.height);
        return pdf;
    }

    addPageToPdf(pdf: jspdf, contentDataURL: string): void {
        var page2 = pdf.addPage('a4', 'p');
        page2.addImage(contentDataURL, this.pdfOptions.imgType, this.pdfOptions.x, this.pdfOptions.y, this.pdfOptions.width, this.pdfOptions.height);
    }

    savePdf(pdf: jspdf, cvName: string): void {
        pdf.save(cvName + '.pdf');
    }

    createDocx(cvData: EditorCv, inEnglish: boolean, isPrivate: boolean): Observable<Document> {
        const borderstyle = {
            top: { style: BorderStyle.NONE, size: 0, color: '' },
            bottom: { style: BorderStyle.NONE, size: 0, color: '' },
            left: { style: BorderStyle.NONE, size: 0, color: '' },
            right: { style: BorderStyle.NONE, size: 0, color: '' }
        };
        const grayBorderstyle = {
            top: { style: BorderStyle.SINGLE, size: 30, color: 'F0F0F0' },
            bottom: { style: BorderStyle.SINGLE, size: 30, color: 'F0F0F0' },
            left: { style: BorderStyle.SINGLE, size: 30, color: 'F0F0F0' },
            right: { style: BorderStyle.SINGLE, size: 30, color: 'F0F0F0' }
        };
        const grayShading = {
            fill: 'F0F0F0'
        };
        const doc = new Document();

        const section = {
            footers: {
                default: new Footer()
            },
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: '-',
                            size: 1
                        })
                    ]
                }),
                new Table({
                    width: {
                        size: 100,
                        type: WidthType.AUTO
                    },
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            text: inEnglish ? 'About me' : 'Om mig',
                                            heading: HeadingLevel.HEADING_2
                                        }),
                                        new Paragraph(cvData.aboutMe),
                                        new Paragraph(''),
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: inEnglish ? 'Contact' : 'Kontakt',
                                                    bold: true
                                                })
                                            ]
                                        }),
                                        new Paragraph(cvData.phone),
                                        new Paragraph(cvData.email),
                                        new Paragraph(cvData.street + ' ' + cvData.zip + ' ' + cvData.city),
                                        new Paragraph('')
                                    ],
                                    borders: grayBorderstyle,
                                    shading: grayShading
                                })
                            ]
                        })
                    ]
                }),
                new Paragraph(''),
                new Paragraph({
                    text: inEnglish ? 'Experience' : 'Erfarenhet',
                    heading: HeadingLevel.HEADING_2
                }),
                new Paragraph('')
            ] as any
        };

        for (let exp of cvData.experienceList) {
            if (exp.heading === 'Hitta.se') section.children.push(new Paragraph(''));
            if (exp.heading === 'Hitta.se' && inEnglish) section.children.push(new Paragraph(''));
            section.children.push(
                new Paragraph({
                    text: exp.heading + (isPrivate ? (' (' + (inEnglish ? 'consultant from ' : 'konsult via ') + exp.employment + ')') : ''),
                    heading: HeadingLevel.HEADING_3
                })
            );
            section.children.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: exp.subheading + ' / ' + exp.location + ' / ' + exp.from + ' - ' + exp.to,
                            bold: true
                        })
                    ]
                })
            );
            section.children.push(new Paragraph(exp.text));
            section.children.push(new Paragraph(''));
        }

        if (isPrivate) {
            section.children.push(new Paragraph(''));
            var employmentCell = {
                children: [
                    new Paragraph({
                        text: inEnglish ? 'Employments' : 'Anställningar',
                        heading: HeadingLevel.HEADING_2
                    }),
                    new Paragraph('')
                ],
                borders: grayBorderstyle,
                shading: grayShading,
                width: {
                    size: 100,
                    type: WidthType.PERCENTAGE
                }
            };
    
            for (let emp of cvData.employmentList) {
                employmentCell.children.push(
                    new Paragraph({
                        text: emp.heading,
                        heading: HeadingLevel.HEADING_3
                    })
                );
                employmentCell.children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: emp.location + ', ' + emp.from + ' - ' + emp.to,
                                bold: true
                            })
                        ]
                    })
                );
                employmentCell.children.push(new Paragraph(''));
            }
    
            section.children.push(new Table({
                width: {
                    size: 100,
                    type: WidthType.AUTO
                },
                rows: [
                    new TableRow({
                        children: [new TableCell(employmentCell)]
                    })
                ]
            }));
        }

        section.children.push(new Paragraph(''));
        var educationCell = {
            children: [
                new Paragraph({
                    text: inEnglish ? 'Education' : 'Utbildning',
                    heading: HeadingLevel.HEADING_2
                }),
                new Paragraph('')
            ],
            borders: isPrivate ? borderstyle : grayBorderstyle,
            shading: isPrivate ? {} : grayShading,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            }
        };

        for (let edu of cvData.educationList) {
            educationCell.children.push(
                new Paragraph({
                    text: edu.heading,
                    heading: HeadingLevel.HEADING_3
                })
            );
            educationCell.children.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: edu.subheading,
                            bold: true
                        })
                    ]
                })
            );
            educationCell.children.push(new Paragraph(edu.text));
            educationCell.children.push(new Paragraph(''));
        }

        section.children.push(new Table({
            width: {
                size: 100,
                type: WidthType.AUTO
            },
            rows: [
                new TableRow({
                    children: [new TableCell(educationCell)]
                })
            ]
        }));

        section.children.push(new Paragraph(''));
        var competenceCell = {
            children: [
                new Paragraph({
                    text: inEnglish ? 'Competence' : 'Kompetenser',
                    heading: HeadingLevel.HEADING_2
                }),
                new Paragraph('')
            ] as any,
            borders: isPrivate ? grayBorderstyle : borderstyle,
            shading: isPrivate ? grayShading : {},
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            }
        };

        var competenceRow = [];
        var competenceTable = {
            width: {
                size: 100,
                type: WidthType.AUTO
            },
            rows: []
        };
        for (let i = 0; i < cvData.competence.length; i++) {
            competenceRow.push(cvData.competence[i]);
            if (competenceRow.length === 3 || i === (cvData.competence.length - 1)) {
                var row = {
                    children: []
                };

                for (let comp of competenceRow) {
                    row.children.push(new TableCell({
                        children: [
                            new Paragraph(comp),
                        ],
                        borders: borderstyle,
                        width: {
                            size: 33,
                            type: WidthType.PERCENTAGE,
                        },
                    }));
                }

                competenceTable.rows.push(new TableRow(row));
                competenceRow = [];
            }
        }
        competenceCell.children.push(new Table(competenceTable));
        competenceCell.children.push(new Paragraph(''));

        section.children.push(new Table({
            width: {
                size: 100,
                type: WidthType.AUTO
            },
            rows: [
                new TableRow({
                    children: [new TableCell(competenceCell)]
                })
            ]
        }));

        section.children.push(new Paragraph(''));
        var miscCell = {
            children: [
                new Paragraph({
                    text: inEnglish ? 'Misc' : 'Övrigt',
                    heading: HeadingLevel.HEADING_2
                }),
                new Paragraph(cvData.misc),
                new Paragraph(''),
                new Paragraph({
                    children: [
                        new TextRun({
                            text: inEnglish ? 'Conferences' : 'Konferenser',
                            bold: true
                        })
                    ]
                })
            ],
            borders: isPrivate ? borderstyle : grayBorderstyle,
            shading: isPrivate ? {} : grayShading,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            }
        };

        for (let conf of cvData.conferenceList) {
            miscCell.children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: conf.heading,
                        bold: true
                    }),
                    new TextRun(' ' + conf.location + ' ' + conf.from + ' - ' + conf.text),
                ]
            }));
        }

        section.children.push(new Table({
            width: {
                size: 100,
                type: WidthType.AUTO
            },
            rows: [
                new TableRow({
                    children: [new TableCell(miscCell)]
                })
            ]
        }));

        return Observable.create(observer => {
            this.http.get(cvData.imageLink, { responseType: 'arraybuffer' }).subscribe(result => {
                this.http.get('assets/img/precurse-inv.png', { responseType: 'arraybuffer' }).subscribe(result2 => {

                    const image = Media.addImage(doc, result);
                    const logo = Media.addImage(doc, result2, 150, 45);

                    section.children.unshift(
                        new Table({
                            width: {
                                size: 100,
                                type: WidthType.AUTO
                            },
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [
                                                new Paragraph({
                                                    text: cvData.name,
                                                    heading: HeadingLevel.HEADING_1
                                                }),
                                                new Paragraph({
                                                    text: cvData.title,
                                                    heading: HeadingLevel.HEADING_4
                                                })
                                            ],
                                            borders: borderstyle,
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                                new Paragraph({
                                                    children: [image],
                                                    alignment: AlignmentType.RIGHT,
                                                })
                                            ],
                                            borders: borderstyle,
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            }
                                        })
                                    ]
                                })
                            ]
                        })
                    );

                    section.footers.default = new Footer({
                        children: [
                            new Table({
                                width: {
                                    size: 100,
                                    type: WidthType.AUTO
                                },
                                rows: [
                                    new TableRow({
                                        children: [new TableCell({
                                            children: [
                                                new Paragraph({
                                                    children: [logo],
                                                    alignment: AlignmentType.RIGHT,
                                                })
                                            ],
                                            borders: borderstyle,
                                            shading: {
                                                fill: '03037C'
                                            },
                                            width: {
                                                size: 100,
                                                type: WidthType.PERCENTAGE
                                            }
                                        })]
                                    })
                                ]
                            })
                        ]
                    });

                    doc.addSection(section);
                    observer.next(doc);
                });
            });
        });
    }

    saveDocx(doc: Document, cvName: string, inEnglish: boolean): void {
        const filename = cvName + ' (' + (inEnglish ? 'text only' : 'endast text') + ').docx';
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, filename);
        });
    }
}