import { Component, Input, OnChanges } from '@angular/core';
import { TimelineItem } from '../../classes/timelineItem';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnChanges {

  @Input() items: TimelineItem[];
  @Input('show-employment') showEmployment: boolean;
  @Input('in-english') inEnglish: boolean;

  private consultantText: string;

  ngOnChanges() {
    this.consultantText = this.inEnglish ? 'consultant from' : 'konsult via';
  }
}