import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Handles emails
@Injectable()
export class EmailService {

  constructor(private http: HttpClient) { }

  send(name: string, email: string, phone: string, message: string): Observable<any> {

    const jsonObj = {
      "name": name,
      "email": email,
      "phone": phone,
      "message": message
    };

    return this.http.post<any>('http://www.precurse.se/mailEngine.php', JSON.stringify(jsonObj));
  }
}