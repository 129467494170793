import { Component, OnInit, Input } from '@angular/core';
import { SectionItem } from '../../classes/sectionItem';
import { DataService } from '../../services/data.service';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'precurse-section',
  templateUrl: './section.component.html'
})
export class SectionComponent implements OnInit {

  @Input() section: SectionItem;
    
  private sendText: string;
  private namePlaceholder: string;
  private emailPlaceholder: string;
  private phonePlaceholder: string;
  private messagePlaceholder: string;
  private successMessage: string;
  private failMessage: string;
  private validateMessage: string;

  private name: string;
  private email: string;
  private phone: string;
  private message: string;
  private resultMessage: string;

  private alertType: string;
  private isLoading: boolean = false;

  constructor(private dataService: DataService, private emailService: EmailService) { }

  ngOnInit() {
    this.dataService.getSiteData(this.section.inEnglish)
      .subscribe(data => {
        this.sendText = data.send;
        this.namePlaceholder = data.namePlaceholder + ' *';
        this.emailPlaceholder = data.emailPlaceholder + ' *';
        this.phonePlaceholder = data.phonePlaceholder;
        this.messagePlaceholder = data.messagePlaceholder + ' *';
        this.successMessage = data.successMessage;
        this.failMessage = data.failMessage;
        this.validateMessage = data.validateMessage;
      });
  }

  private sendEmail() {
    if (this.name && this.name.length > 0 &&
        this.email && this.email.length > 0 &&
        this.message && this.message.length > 0) {
                
      this.isLoading = true;
      this.emailService.send(this.name, this.email, this.phone, this.message)
        .subscribe(response => {
          const wasSent = response.sent;
          this.alertType = wasSent ? 'success' : 'danger';
          this.resultMessage = wasSent
            ? this.successMessage
            : this.failMessage;
          this.name = '';
          this.email = '';
          this.phone = '';
          this.message = '';
          this.isLoading = false;
        });
      } else {
        this.alertType = 'danger';
        this.resultMessage = this.validateMessage;
      }
  }

  private closeAlert() {
    this.resultMessage = '';
  }
}