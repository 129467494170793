declare var $: any;
import { Component, OnInit } from '@angular/core';
import { SectionItem, SectionItemChild, SectionItemChildIconLink } from '../../classes/sectionItem';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'start',
  templateUrl: './start.component.html'
})
export class StartComponent {

  leadIn: string;
  heading: string;
  headerBtn: string;
  headerBtnLink: string;
  copyright: string;
  email: string;
  language: string;
  sections: SectionItem[] = [];

  private inEnglish: boolean = true;

  constructor(private dataService: DataService) {
    this.getData();
  }

  ngOnInit() {
    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
      target: 'navbar > .navbar-fixed-top',
      offset: 51
    });

    // Offset for Main Navigation
    $('#mainNav').affix({
      offset: {
        top: 100
      }
    });

    document.querySelector('video').playbackRate = 0.5;
  }

  headerBtnClicked(event) {
    $('html, body').stop().animate({
      scrollTop: ($(this.headerBtnLink).offset().top - 50)
    }, 1250, 'easeInOutExpo');
    event.preventDefault();
  }

  getData() {
    this.inEnglish = !this.inEnglish;
    this.dataService.getSiteData(this.inEnglish)
      .subscribe(data => {
        this.leadIn = data.leadIn;
        this.heading = data.heading;
        this.headerBtn = data.headerBtn;
        this.copyright = data.copyright + new Date().getFullYear().toString();
        this.email = data.email;
        this.language = data.language;
        this.sections = [];

        data.sections.forEach(section => {
          var children: SectionItemChild[] = [];

          section.children.forEach(child => {
            var iconLinks: SectionItemChildIconLink[] = [];
            child.iconLinks.forEach(iconLink => iconLinks.push(new SectionItemChildIconLink(iconLink.link, iconLink.image, iconLink.description)));
            children.push(new SectionItemChild(child.title, child.text, child.description, child.image, child.class, iconLinks));
          });

          this.sections.push(new SectionItem(section.name, section.title, section.description, children, section.extraClass, section.imageSection, section.isContactForm, this.inEnglish));
        });
        this.headerBtnLink = '#' + this.sections[0].name;
      });
  }
}
