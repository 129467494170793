import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './components/start/start.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SectionComponent } from './components/section/section.component';
import { DataService } from './services/data.service';
import { EmailService } from './services/email.service';
import { LoginModalComponent } from './components/modals/login-modal/login-modal.component';
import { EditorComponent } from './components/editor/editor.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { ListEditorComponent } from './components/list-editor/list-editor.component';
import { ChristmasComponent } from './components/christmas/christmas.component';
import { FileService } from './services/file.service';
import { RoundPipe } from './round.pipe';

const firebaseConfig = {
  apiKey: "AIzaSyAx7KnN2P0alOuoqkj9cR6uBMBTNAxvaHM",
  authDomain: "precurse-base.firebaseapp.com",
  databaseURL: "https://precurse-base.firebaseio.com",
  projectId: "precurse-base",
  storageBucket: "precurse-base.appspot.com",
  messagingSenderId: "450555903779",
  appId: "1:450555903779:web:bf3d861759aa90ca"
};

@NgModule({
  declarations: [AppComponent, StartComponent, NavbarComponent, SectionComponent, LoginModalComponent, EditorComponent, TimelineComponent, ListEditorComponent, ChristmasComponent, RoundPipe],
  imports: [BrowserModule, FormsModule, HttpClientModule, AppRoutingModule, AngularFireModule.initializeApp(firebaseConfig), AngularFirestoreModule, AngularFireStorageModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, DataService, EmailService, FileService],
  bootstrap: [AppComponent]
})
export class AppModule { }
