import { Component, OnInit } from '@angular/core';
import { ChristmasItem } from '../../classes/christmasItem';
import { ChristmasList } from '../../classes/christmasList';
import { DataService } from '../../services/data.service';
declare var FB: any;

@Component({
  selector: 'christmas',
  templateUrl: './christmas.component.html',
  styleUrls: ['../editor/editor.component.css', './christmas.component.css']
})
export class ChristmasComponent implements OnInit {

  christmasList: ChristmasList = {} as ChristmasList;
  editObject: ChristmasItem = new ChristmasItem();
  loading: boolean;
  showConfirm: boolean;

  private removeIndex: number; 

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.loading = true;

    /* (window as any).fbAsyncInit = function() {
      FB.init({
        appId      : '467328113917689',
        cookie     : true,
        xfbml      : true,
        version    : 'v3.1'
      });
      FB.AppEvents.logPageView();
    };

    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk')); */

    this.dataService.getChrismasList().subscribe((list: ChristmasList) => {
      this.christmasList = list;
      this.loading = false;
    });
  }

  addItem() {
    if (this.editObject.text && this.editObject.text.length > 0 &&
        this.editObject.name && this.editObject.name.length > 0) {
      this.christmasList.itemList.push(this.editObject);
      this.saveData();
      this.clearEdit();
    }
  }

  confirmRemoveItem(index: number) {
    this.removeIndex = index;
    this.showConfirm = true;
  }

  removeItem() {
    this.christmasList.itemList.splice(this.removeIndex, 1);
    this.saveData();
    this.clearEdit();
  }

  clearEdit() {
    this.showConfirm = false;
    this.removeIndex = null;
    this.editObject = new ChristmasItem();
  }

  /* addUserToItem(index: number) {
    var that = this;
    FB.login((response)=>
    {
      if (response.authResponse)
      {
        FB.api(
          "/" + response.authResponse.userID + "/",
          function (response) {
            if (response && !response.error) {
              that.christmasList.itemList[index].name = (response.name || '').split(' ')[0];
              that.saveData();
            }
          }
        );
      }
      else
      {
        console.log('User login failed');
      }
    });
  } */

  private saveData() {
    this.loading = true;
    this.dataService.updateChrismasList(this.christmasList).then(() => {
      this.loading = false;
    });
  }
}