export class SectionItem {

    name: string = '';
    title: string = '';
    description: string = '';
    children: SectionItemChild[] = [];
    extraClass: string = '';
    imageSection: boolean = false;
    isContactForm: boolean = false;
    inEnglish: boolean = false;

    constructor(name: string, title: string, description: string, children: SectionItemChild[], extraClass: string = '', imageSection: boolean = false, isContactForm: boolean = false, inEnglish: boolean = false) {
        this.name = name;
        this.title = title;
        this.description = description;
        this.children = children;
        this.extraClass = extraClass;
        this.imageSection = imageSection;
        this.isContactForm = isContactForm;
        this.inEnglish = inEnglish;
    }
}

export class SectionItemChild {

    title: string = '';
    text: string = '';
    description: string = '';
    image: string = null;
    extraClass: string = '';
    iconLinks: SectionItemChildIconLink[] = [];

    constructor(title: string, text: string, description: string, image: string = null, extraClass: string = '', iconLinks: SectionItemChildIconLink[] = []) {
        this.title = title;
        this.text = text;
        this.description = description;
        this.image = (image || '').length > 0 ? 'assets/img/' + image : null;
        this.extraClass = extraClass;
        this.iconLinks = iconLinks;
    }
}

export class SectionItemChildIconLink {

    link: string = '';
    image: string = '';
    description: string = '';

    constructor(link: string, image: string, description: string) {
        this.link = link;
        this.image = 'assets/img/' + image;
        this.description = description;
    }
}