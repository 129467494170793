import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StartComponent } from './components/start/start.component';
import { EditorComponent } from './components/editor/editor.component';
import { ChristmasComponent } from './components/christmas/christmas.component';

const routes: Routes = [
  { path: '', redirectTo: '/start', pathMatch: 'full' },
  { path: 'start', component: StartComponent },
  { path: 'editor', component: EditorComponent },
  { path: 'editor/:user', component: EditorComponent },
  { path: 'christmas', component: ChristmasComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
