declare var $: any;
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html'
})
export class LoginModalComponent {

  private correctUsername: string = 'jacob.haraldsson@precurse.se';
  private correctPassword: string = 'bocajjacob84';

  username: string;
  password: string;
  loginError: boolean;

  constructor(private router: Router) { }

  logIn() {
    if (this.username === this.correctUsername && this.password === this.correctPassword) {
      $('body').removeClass('modal-open');
      $('.modal-backdrop').hide();
      const encodedString = btoa(this.correctUsername + ':' + this.correctPassword);
      this.router.navigateByUrl('/editor/' + encodedString);
    } else {
      this.loginError = true;
    }
  }
}