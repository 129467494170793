import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TimelineItem } from '../classes/timelineItem';
import 'rxjs/add/operator/map';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { EditorCv, EditorInvoice, InvoiceItem } from '../classes/editorCv';
import { ChristmasList } from '../classes/christmasList';
import { ChristmasItem } from '../classes/christmasItem';

// Handles upload to and download from Firebase and JSON files
@Injectable()
export class DataService {

  private cvRef: AngularFirestoreDocument<EditorCv>;
  private christmasRef: AngularFirestoreDocument<ChristmasList>;
  private invocieRef: AngularFirestoreDocument<EditorInvoice>;
  private dataJsonUrlSwe: string = 'assets/data/data-swe.json';
  private dataJsonUrlEng: string = 'assets/data/data-eng.json';

  constructor(private http: HttpClient, private db: AngularFirestore, private storage: AngularFireStorage) { }

  getSiteData(inEnglish: boolean = false): Observable<any> {
    return this.http.get<any>(inEnglish ? this.dataJsonUrlEng : this.dataJsonUrlSwe);
  }

  getCvData(inEnglish: boolean = false): Observable<EditorCv> {
    const id = inEnglish ? 'on2x525hiewf2IyNMkOs' : 'N51elSlmN81RIHir4Fce';
    this.cvRef = this.db.doc<EditorCv>('precurse-editor/' + id);
    return this.cvRef.valueChanges().map((cv: EditorCv) => {

      cv.experienceList = [];
      for (let i = 0; i < cv.experience.length; i++) {
        let experienceParts = cv.experience[i].split(';');
        let experienceItem = new TimelineItem(experienceParts[0], experienceParts[1], experienceParts[2], experienceParts[3], experienceParts[4], experienceParts[5], experienceParts[6]);
        cv.experienceList.push(experienceItem);
      }
      cv.experienceListParts = [];
      var currentPageOfExperience: TimelineItem[] = [];
      cv.experienceList.sort((a, b) => this.sortMonthAndYear(a.to, b.to) !== 0 ? this.sortMonthAndYear(a.to, b.to) : this.sortMonthAndYear(a.from, b.from));
      for (let i = 0; i < cv.experienceList.length; i++) {
        currentPageOfExperience.push(cv.experienceList[i]);
        if (currentPageOfExperience.length === 6) {
          cv.experienceListParts.push(currentPageOfExperience);
          currentPageOfExperience = [];
        }
      }
      if (currentPageOfExperience.length >= 0) cv.experienceListParts.push(currentPageOfExperience);

      cv.employmentList = [];
      for (let i = 0; i < cv.employments.length; i++) {
        let employmentParts = cv.employments[i].split(';');
        let employmentItem = new TimelineItem(employmentParts[0], '', employmentParts[1], employmentParts[2], employmentParts[3], '', employmentParts[4]);
        cv.employmentList.push(employmentItem);
      }
      cv.employmentList.sort((a, b) => this.sortMonthAndYear(a.to, b.to) !== 0 ? this.sortMonthAndYear(a.to, b.to) : this.sortMonthAndYear(a.from, b.from));
      cv.educationList = [];
      for (let i = 0; i < cv.education.length; i++) {
        let educationParts = cv.education[i].split(';');
        let educationItem = new TimelineItem(educationParts[0], educationParts[1], educationParts[2], educationParts[3], educationParts[4], educationParts[5]);
        cv.educationList.push(educationItem);
      }
      cv.educationList.sort((a, b) => this.sortMonthAndYear(a.to, b.to) !== 0 ? this.sortMonthAndYear(a.to, b.to) : this.sortMonthAndYear(a.from, b.from));
      cv.conferenceList = [];
      for (let i = 0; i < cv.conferences.length; i++) {
        let conferenceParts = cv.conferences[i].split(';');
        let conferenceItem = new TimelineItem(conferenceParts[0], conferenceParts[1], conferenceParts[2], conferenceParts[3], conferenceParts[4], conferenceParts[5]);
        cv.conferenceList.push(conferenceItem);
      }
      cv.conferenceList.sort((a, b) => this.sortMonthAndYear(a.from, b.from));
      cv.competence.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
      return cv;
    });
  }

  updateCvData(cv: EditorCv): Promise<any> {
    cv.experience = [];
    for (let i = 0; i < cv.experienceList.length; i++) {
      let experienceString = cv.experienceList[i].heading + ';' + cv.experienceList[i].subheading + ';' + cv.experienceList[i].location + ';' + cv.experienceList[i].from + ';' + cv.experienceList[i].to + ';' + cv.experienceList[i].text + ';' + cv.experienceList[i].employment;
      cv.experience.push(experienceString);
    }
    cv.employments = [];
    for (let i = 0; i < cv.employmentList.length; i++) {
      let employmentString = cv.employmentList[i].heading + ';' + cv.employmentList[i].location + ';' + cv.employmentList[i].from + ';' + cv.employmentList[i].to;
      cv.employments.push(employmentString);
    }
    cv.education = [];
    for (let i = 0; i < cv.educationList.length; i++) {
      let educationString = cv.educationList[i].heading + ';' + cv.educationList[i].subheading + ';' + cv.educationList[i].location + ';' + cv.educationList[i].from + ';' + cv.educationList[i].to + ';' + cv.educationList[i].text;
      cv.education.push(educationString);
    }
    cv.conferences = [];
    for (let i = 0; i < cv.conferenceList.length; i++) {
      let conferenceString = cv.conferenceList[i].heading + ';' + cv.conferenceList[i].subheading + ';' + cv.conferenceList[i].location + ';' + cv.conferenceList[i].from + ';' + cv.conferenceList[i].to + ';' + cv.conferenceList[i].text;
      cv.conferences.push(conferenceString);
    }

    return this.cvRef.update({
      aboutMe: cv.aboutMe,
      city: cv.city,
      competence: cv.competence,
      conferences: cv.conferences,
      education: cv.education,
      email: cv.email,
      employments: cv.employments,
      experience: cv.experience,
      imageName: cv.imageName,
      misc: cv.misc,
      name: cv.name,
      phone: cv.phone,
      street: cv.street,
      title: cv.title,
      zip: cv.zip
    });
  }

  getImageLink(filename: string): Observable<string> {
    const ref = this.storage.ref(filename);
    return ref.getMetadata().map((response: any) => {
      return 'https://firebasestorage.googleapis.com/v0/b/precurse-base.appspot.com/o/' + filename + '?alt=media';
    });
  }

  uploadFile(file: any): Observable<any> {
    const ref = this.storage.ref(file.name);
    const task = ref.put(file);
    return task.snapshotChanges();
  }

  getChrismasList(): Observable<ChristmasList> {
    const id = 'yPDOq6fFZrdotaf186p9';
    this.christmasRef = this.db.doc<ChristmasList>('christmas/' + id);
    return this.christmasRef.valueChanges().map((list: ChristmasList) => {
      list.itemList = [];
      for (let i = 0; i < list.list.length; i++) {
        let parts = list.list[i].split(';');
        let item = new ChristmasItem(parts[0], parts[1]);
        list.itemList.push(item);
      }
      return list;
    });
  }

  updateChrismasList(list: ChristmasList): Promise<any> {
    list.list = [];

    for (let i = 0; i < list.itemList.length; i++) {
      let string = list.itemList[i].text + ';' + list.itemList[i].name;
      list.list.push(string);
    }

    return this.christmasRef.update({
      list: list.list
    });
  }

  getInvoiceData(): Observable<EditorInvoice> {
    const id = '4LP8VObuCUPPeMuU6lgK';
    this.invocieRef = this.db.doc<EditorInvoice>('precurse-editor/' + id);
    return this.invocieRef.valueChanges().map((invoice: EditorInvoice) => {
      const recipientParts = invoice.recipient.split(';');
      invoice.recipientCompany = {
        name: recipientParts[0],
        street: recipientParts[1],
        zip: recipientParts[2],
        city: recipientParts[3]
      };
      invoice.itemList = [];
      for (let i = 0; i < invoice.items.length; i++) {
        let item = new TimelineItem(invoice.items[i].description, '', '', '', '', '', '', invoice.items[i].hours, invoice.items[i].rate);
        invoice.itemList.push(item);
      }
      return invoice;
    });
  }

  updateInvoiceData(invoice: EditorInvoice): Promise<any> {
    invoice.items = [];
    for (let i = 0; i < invoice.itemList.length; i++) {
      let item = {
        description: invoice.itemList[i].heading,
        hours: invoice.itemList[i].hours,
        rate: invoice.itemList[i].rate
      } as InvoiceItem;
      invoice.items.push(item);
    }

    return this.invocieRef.update({
      account: invoice.account,
      city: invoice.city,
      email: invoice.email,
      invoiceNumber: invoice.invoiceNumber,
      items: invoice.items,
      orgNumber: invoice.orgNumber,
      paymentDays: invoice.paymentDays,
      phone: invoice.phone,
      recipient: invoice.recipientCompany.name + ';' + invoice.recipientCompany.street + ';' + invoice.recipientCompany.zip + ';' + invoice.recipientCompany.city,
      reference: invoice.reference,
      street: invoice.street,
      vatNumber: invoice.vatNumber,
      website: invoice.website,
      zip: invoice.zip
    });
  }

  private sortMonthAndYear(monthAndYearA: string, monthAndYearB: string): number {
    const nowSort = ['nu', 'nuvarande', 'now', 'present'];
    if (nowSort.indexOf(monthAndYearB) !== -1)
      return 1;
    else if (nowSort.indexOf(monthAndYearA) !== -1)
      return -1;

    const monthAndYearAParts = monthAndYearA.split(' ');
    const monthA = monthAndYearAParts.length > 1 ? monthAndYearAParts[0] : 'jan';
    const yearA = parseInt(monthAndYearAParts.length > 1 ? monthAndYearAParts[1] : monthAndYearAParts[0]);
    const monthAndYearBParts = monthAndYearB.split(' ');
    const monthB = monthAndYearBParts.length > 1 ? monthAndYearBParts[0] : 'jan';
    const yearB = parseInt(monthAndYearBParts.length > 1 ? monthAndYearBParts[1] : monthAndYearBParts[0]);
    const monthSort = ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

    if (yearB > yearA)
      return 1;
    else if (yearB < yearA)
      return -1;
    else {
      const monthAIndex = monthSort.indexOf(monthA.toLowerCase());
      const monthBIndex = monthSort.indexOf(monthB.toLowerCase());

      if (monthBIndex > monthAIndex)
        return 1;
      else if (monthBIndex < monthAIndex)
        return -1;
      else
        return 0;
    }
  }
}
