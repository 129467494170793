export class TimelineItem {

  heading: string = '';
  subheading: string = '';
  location: string = '';
  text: string = '';
  from: string = '';
  to: string = '';
  employment: string = '';
  hours: number;
  rate: number;

  constructor(heading?: string, subheading?: string, location?: string, from?: string, to?: string, text?: string, employment?: string, hours?: number, rate?: number) {
    this.heading = heading || '';
    this.subheading = subheading || '';
    this.location = location || '';
    this.text = text || '';
    this.from = from || '';
    this.to = to || '';
    this.employment = employment || '';
    this.hours = hours;
    this.rate = rate;
  }
}