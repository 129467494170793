import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TimelineItem } from '../../classes/timelineItem';

@Component({
  selector: 'list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['../editor/editor.component.css', './list-editor.component.css']
})
export class ListEditorComponent implements OnChanges {

  @Input() name: string;
  @Input('object-list') objectList: TimelineItem[] = [];
  @Input('string-list') stringList: string[] = [];
  @Input('hide-both-years') hideBothYears: boolean;
  @Input('hide-one-year') hideOneYear: boolean;
  @Input('hide-location') hideLocation: boolean;
  @Input('hide-subheading') hideSubheading: boolean;
  @Input('hide-textbox') hideTextbox: boolean;
  @Input('show-employment') showEmployment: boolean;
  @Input('show-hours') showHours: boolean;
  @Input('show-rate') showRate: boolean;

  @Output('object-list-change') objectListChange: EventEmitter<TimelineItem[]> = new EventEmitter<TimelineItem[]>();
  @Output('string-list-change') stringListChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  private editObject: TimelineItem = new TimelineItem();
  private isUpdate: boolean;
  private updateIndex: number;
  private removeIndex: number;

  isObjectList: boolean;
  showEdit: boolean;
  showConfirm: boolean;

  ngOnChanges() {
    this.isObjectList = this.objectList && this.objectList.length > 0;
  }

  addItem() {
    if (this.isObjectList) {
      this.objectList.push(this.editObject);
      this.objectListChange.emit(this.objectList);
    } else {
      this.stringList.push(this.editObject.heading);
      this.stringListChange.emit(this.stringList);
    }
    this.clearEdit();
  }

  confirmRemoveItem(index: number) {
    this.removeIndex = this.showConfirm ? null : index;
    this.showConfirm = !this.showConfirm;
  }

  removeItem() {
    if (this.isObjectList) {
      this.objectList.splice(this.removeIndex, 1);
      this.objectListChange.emit(this.objectList);
    } else {
      this.stringList.splice(this.removeIndex, 1);
      this.stringListChange.emit(this.stringList);
    }
    this.clearEdit();
  }

  editItem(index: number) {
    if (this.isObjectList) {
      this.editObject = new TimelineItem(this.objectList[index].heading, this.objectList[index].subheading, this.objectList[index].location, this.objectList[index].from, this.objectList[index].to, this.objectList[index].text, this.objectList[index].employment, this.objectList[index].hours, this.objectList[index].rate);
    } else {
      this.editObject = new TimelineItem(this.stringList[index]);
    }
    this.showEdit = true;
    this.isUpdate = true;
    this.updateIndex = index;
  }

  clearEdit() {
    this.isUpdate = false;
    this.showEdit = false;
    this.showConfirm = false;
    this.updateIndex = null;
    this.removeIndex = null;
    this.editObject = new TimelineItem();
  }

  updateItem() {
    if (this.isObjectList) {
      this.objectList[this.updateIndex] = this.editObject;
      this.objectListChange.emit(this.objectList);
    } else {
      this.stringList[this.updateIndex] = this.editObject.heading;
      this.stringListChange.emit(this.stringList);
    }
    this.clearEdit();
  }
}