declare var $: any;
import { Component, Input } from '@angular/core';
import { SectionItem } from '../../classes/sectionItem';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent {

    @Input() links: SectionItem[] = [];
    showMenu: boolean = true;
    private menuBtnClicks: number = 0;

    constructor() { }

    linkClicked(event) {
        var $anchor = $(event.currentTarget);
        var href = $anchor.attr('href');
        $('html, body').stop().animate({
            scrollTop: ($(href).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
        if (this.menuBtnClicks > 0) this.showMenu = false;
    }

    menuClicked() {
        if (this.menuBtnClicks > 0) {
            this.showMenu = !this.showMenu;
        } else {
            this.menuBtnClicks++;
        }
    }
}